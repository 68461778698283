<template lang="en-us">
    <div class="loading-box">
      <img src="../assets/logo.png" class="loader-logo"/>
      <p class="loader-title-tag pc">Welcome to DynaQuest Energy Limited</p>
      <p class="loader-subtitle-tag">World Class Providers of Quality & Timely Service</p>
    </div>
</template>
<script>
export default {
    name:"LoaderComp"
}
</script>
<style>
@import url('../styles/loader.css');
</style>