<template>
    <div>
        <TopComp/>
        <MenuHeader></MenuHeader>
        <!-- <AnnouncementComp></AnnouncementComp> -->
    </div>
</template>
<script>
import MenuHeader from './MenuHeader.vue';
import TopComp from './TopComp.vue';
// import AnnouncementComp from './AnnoucementComp.vue'
export default {
    components:{
        TopComp,
        MenuHeader,
        // AnnouncementComp
    }
}
</script>
<style lang="">
    
</style>