<template>
    <div class="footer-main">
        <div style="text-align:left;display: flex;align-items: flex-start;justify-content: center;flex-direction: column;">
            <div>
                <img style="margin-bottom:10px" class="menu-logo" src="../../assets/logo.png"/>
            </div>
            <p>We specialize in conventional and Advanced NDT services including Time of Flight Diffraction (TOFD), Phased Array Ultrasonic testing (PAUT), Corrosion Mapping Services (CMS), Long Range Ultrasonic Testing (LRUT), Tubing Inspection and other range of NDT operations.</p>
        </div>
        <div class="quick-link-margin" style="text-align:left;display: flex;align-items: flex-start;justify-content: center;flex-direction: column;">
            <p class="quick-links pc">Quick Links</p>
           <router-link to="/services"><p class="links">About Us</p></router-link>
            <router-link to="/services"><p class="links">Services</p></router-link>
            <router-link to="/gallery"><p class="links">Gallery</p></router-link>
            <router-link to="/register/enquiry"><p class="links">Make Enquiry</p></router-link>
            <router-link to="/policy"><p class="links">Quality Policy</p></router-link>
        </div>
        <div>
            <p class="quick-links pc">Get In Touch</p>
            <p class="tx-left"><i class="fa fa-map-pin"></i>&nbsp; No. 6 New Shell Location/Boskel Road, KM 16, off PH/Aba Expressway Rumuodunwere Elelenwo, Beside Limpopo Hotel, Port Harcourt, Rivers State, Nigeria. <b class="pc">(Nigeria Office)</b></p>
            <p class="links"><i class="fa fa-envelope"></i>&nbsp; <a class="pc" href="mailto:info@dynaquestenergy.com"><b>info@dynaquestenergy.com</b></a></p>
            <p style="text-align: left;"><i class="fa fa-phone"></i>&nbsp; <a class="links pc" href="tel:+2347012329840"><b>+2347012329840</b></a>, <a class="links pc" href="tel:+2348105611959"><b>+2348105611959</b></a></p>
            <div>

            <div style="color: #145E97;display: flex;column-gap: 20px;font-size: 30px;">
                <a class="a-brand" target="_blank" href="https://web.facebook.com/dynaquestenergyltd"><i class="fa fa-facebook"></i></a>
                <a class="a-brand" target="_blank" href="https://www.instagram.com/dynaquestenergylimited/"><i class="fa fa-instagram"></i></a>
                <a class="a-brand" target="_blank" href="https://twitter.com/dynaquestenergy"><i class="fa fa-twitter"></i></a>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style>
    @import url('../../styles/footer.css');
</style>