import { createRouter, createWebHistory }
from "vue-router";
import store from "./store";
import { LOADING_SPINNER_SHOW_MUTATION } from "./store.js";

const routes = [
    {
        path: "/",
        name: "Home",
        meta:{
            title:"Dynaquest Energy Limited",
            metaTags:[
                {
                    name:"description",
                    content:'Dynaquest Energy Limited provides specialized engineering,projects m anagem ent,consultancy,and business development services through dedicated, qualified and innovative-thinking engineers,projects managers,and industry experts with requisite and proven technical expertise.'
                }
            ]
        },
        component: ()=> import('../views/HomePage.vue'),
    },
    {
        path: "/about",
        name: "About",
        meta:{
            title:"Dynaquest | Who we are",
            metaTags:[
                {
                    name:"description",
                    content: "DYNAQUEST ENERGY LIMITED is a company registered under the law of the Federal Republic of Nigeria with the Corporate Affairs Commission and in compliance with the Company and Allied Matters Act 1990 of the Federal Republic of Nigeria with registration number-RC 875753 to provide timely and quality service in the industries."
                }
            ]
        },
        component: ()=> import('../views/AboutPage.vue'),
    },
    {
        path: "/services",
        name: "Services",
        meta:{
            title:"Dynaquest | What we do",
            metaTags:[
                {
                    name:"description",
                    content: "We specialize in conventional and Advanced NDT services including Time of Flight Diffraction (TOFD), Phased Array Ultrasonic testing (PAUT), Corrosion Mapping Services (CMS), Long Range Ultrasonic Testing (LRUT), Tubing Inspection, Helium Leak Testing, Alternating Current Field Measurement (ACFM), Boroscopy, Thermography, Automated Ultrasonic Testing (AUT), High Temperature UT/PAUT, Radiographic Testing (RT), Digital Radiographic Testing (DRT), Magnetic Flux Leakage (MFL), Pulse Eddy Current (PEC), Post Weld Heat Treatment (PWHT), Tank Inspection and Calibration, Positive Material Identification (PMI), Vacuum Box Testing (VBT), Sub Sea Services, Various Training in the Oil and Gas Sector. We have provided services to various clients through safe, cost-effective and quality based technical solutions - satisfying customers need."
                }
            ]
        },
        component: ()=> import('../views/ServicesPage.vue'),
    },
    {
        path: "/strategy",
        name: "Strategy",
        meta:{
            title:"Dynaquest | Execution Strategy",
            metaTags:[
                {
                    name:"description",
                    content: "Our Team of highly Motivated and experience professionals have been providing integrity, critical and quality services to a wide range of clients across most sectors of the industry for over Ten (10) years now. As an independent service provider, we are committed to working with our clients and using the best available solutions to improve service delivery quality and integrity in a cost-effective way"
                }
            ]
        },
        component: ()=> import('../views/StrategyPage.vue'),
    },
    {
        path: "/gallery",
        name: "Gallery",
        meta:{
            title:"Dynaquest | Gallery",
            metaTags:[
                {
                    name:"description",
                    content: "Dynaquest Energy Limited is equipped and focused on value addition through  application and combination of our service"
                }
            ]
        },
        component: ()=> import('../views/IndustriesPage.vue'),
    },
    {
        path: "/publication",
        name: "Publication",
        meta:{
            title:"Dynaquest | Publications",
            metaTags:[
                {
                    name:"description",
                    content: "Dynaquest Energy Limited is equipped and focused on value addition through  application and combination of our service"
                }
            ]
        },
        component: ()=> import('../views/PublicationPage.vue'),
    },
    {
        path: "/register/:purpose",
        name: "Registration",
        meta:{
            title:"Dynaquest | Registration",
            metaTags:[
                {
                    name:"description",
                    content: "DYNAQUEST ENERGY LIMITED is a company registered under the law of the Federal Republic of Nigeria with the Corporate Affairs Commission and in compliance with the Company and Allied Matters Act 1990 of the Federal Republic of Nigeria with registration number-RC 875753 to provide timely and quality service in the industries."
                }
            ]
        },
        component: ()=> import('../views/RegistrationPage.vue'),
    },
    {
        path: "/policy",
        name: "Policy",
        meta:{
            title:"Dynaquest | Quality Policy",
            metaTags:[
                {
                    name:"description",
                    content: "DYNAQUEST ENERGY LIMITED is a company registered under the law of the Federal Republic of Nigeria with the Corporate Affairs Commission and in compliance with the Company and Allied Matters Act 1990 of the Federal Republic of Nigeria with registration number-RC 875753 to provide timely and quality service in the industries."
                }
            ]
        },
        component: ()=> import('../views/QualityPolicy.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    async scrollBehavior() {
        
      store.commit(LOADING_SPINNER_SHOW_MUTATION, true);
      await new Promise(r=>setTimeout(r,1000));
        store.commit(LOADING_SPINNER_SHOW_MUTATION, false);
    //   document.getElementById('app').scrollIntoView({behavior:'smooth'});
   }
  
  })

  router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
  });

  
export default router;