import {createStore} from 'vuex';
export const LOADING_SPINNER_SHOW_MUTATION='[mutations] show loading spinner';
export const LOADING_LANDING_SHOW_MUTATION='[mutations] show landing page';
const store=createStore({
    state(){
        return {
            showLoading:false,
            showLandingPage:true,
        };
    },
    mutations:{ 
        [LOADING_SPINNER_SHOW_MUTATION](state,payload){
            state.showLoading=payload;
        },
        [LOADING_LANDING_SHOW_MUTATION](state,payload){
            state.showLandingPage=payload
        }
    },
})

export default store;