<template>
    <div class="top pbc">

        <a class="a-brand"  href="mailto:info@dynaquestenergy.com"><i class="fas fa-envelope"></i></a>
        <a style="color: white;" target="_blank" href="https://web.facebook.com/dynaquestenergyltd"><i class="fa fa-facebook"></i></a>
        <a style="color: white;" class="a-brand" target="_blank" href="https://www.instagram.com/dynaquestenergylimited/"><i class="fa fa-instagram"></i></a>
        <a style="color: white;" class="a-brand" target="_blank" href="https://twitter.com/dynaquestenergy"><i class="fa fa-twitter"></i></a>
        <div class="separator"></div>
        <i class="fa fa-phone"></i>
        <span>
            <a class="a-white" href="tel:+2347012329840">+2347012329840</a>,
            <a class="a-white" href="tel:+2348105611959">+2348105611959</a>
        </span>
    </div>
</template>
<script>
export default {
    
}
</script>
<style>
    @import url('../../styles/header.css');
</style>