<template>
    <div
        style="align-items: center;position: fixed;z-index:1002;top:0px;width: 100%;display: flex;justify-content: center;">
        <div class="landing-loading-box" @click="closeLandingPage()"
        style="display: flex;justify-content: flex-end;padding:0px 30px 0px 0px;">
            <i class="fa fa-close" style="font-size: 30px;color:black;"></i>
        </div>
        
        <div class="landing-main">
            <div style="display: flex;align-items: center;justify-content: center;column-gap: 5px;">
                <div class="line-div"></div>
                <p class="landing-header">Training Offers</p>
                <div class="line-div"></div>
            </div>
            <div class="landing-img-div">
                <i class="fa fa-chevron-left" @click="nextSlide()" style="cursor: pointer;"></i>
                <img :src="currentSlide" class="landing-img" />
                <i class="fa fa-chevron-right" @click="nextSlide()" style="cursor: pointer;"></i>
            </div>
            <button @click="RegisterNow()" class="home-button-style"
                style="font-size: 15px;font-weight: bold;padding: 10px 30px 10px 30px;width: 100%;margin-top: 10px;">Register
                Now</button>
        </div>
    </div>
</template>
<script>
import store from "../services/store";
import { LOADING_LANDING_SHOW_MUTATION } from "../services/store";
export default {
    data() {
        return {
            currentSlide: require("../assets/cswiptraining.jpg"),
            slides: [
                require("../assets/cswiptraining.jpg"),
                require("../assets/publications/training.png"),
                // require("../assets/weldingtraining.jpg")
            ]
        }
    },
    methods: {
        nextSlide() {
            if (this.currentSlide == require("../assets/cswiptraining.jpg")) {
                this.currentSlide = require("../assets/publications/training.png")
            }
            else {
                this.currentSlide = require("../assets/cswiptraining.jpg")
            }
        },
        closeLandingPage() {
            store.commit(LOADING_LANDING_SHOW_MUTATION, false);
        },
        RegisterNow() {
            store.commit(LOADING_LANDING_SHOW_MUTATION, false);
            this.$router.push("/register/training")
        }
    }
}
</script>
<style>
.line-div {
    height: 3px;
    width: 30px;
    background-color: #145E97;
    margin-bottom: 15px;
}

.landing-loading-box {
    position: absolute;
    opacity: 0.9;
    background-color: white;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
}

.landing-main {
    padding: 20px;
    border-radius: 30px;
    background-color: #9acbf3;
    z-index: 1003;
    margin: 5% 5%;
}

.landing-img {
    height: 350px;
    width: 350px;
}

.landing-header {
    font-weight: bold;
    padding: 0px;
    font-size: 25px;
}

.landing-img-div {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

@media only screen and (max-width: 600px) {
    .landing-img {
        height: 350px;
        width: 350px;
        object-position: center;
        object-fit: contain;
    }

    .landing-main {
        padding: 20px;
        border-radius: 30px;
        background-color: #9acbf3;
        z-index: 1003;
        margin: 10% 5%;
    }
}
</style>